import { PermissionType } from '@common-src/enum/permission-type.enum';
import { ProjectModel } from '@common-src/model/project.model';
import { UserModel } from '@common-src/model/user-model';
import http from '@common-src/service3/http/axios';
import { IResponseData } from '@common-src/interface/reponse-data.interface';
import { ApiMap } from '../api-map';
import { UserStoreModule } from '@common-src/store/modules/user';
import { ProjectEntityModel } from '@common-src/entity-model/project-entity';

/**
 * @description: 获取公钥
 */
export function getPublicKey() {
    let url = ApiMap.getPublicKey;
    return http.request<{ publicKey: string }>(
        {
            url,
            method: 'POST'
        }
    );
}
/**
 * @description: 用户登录
 */
export function login(params: { username: string, password: string, device: string }) {
    let url = ApiMap.loginv2;
    return http.request<IResponseData<{ token: string, refreshToken: string }>>(
        {
            url,
            method: 'POST',
            params
        },
        {
            isTransformRequestResult: false
        }
    );
}

/**
 * @description: 用户登出
 */
export function loginout() {
    let url = ApiMap.loginOut;
    return http.request<IResponseData<{ token: string, refreshToken: string }>>(
        {
            url,
            method: 'POST'
        }
    );
}

/**
 * @description: 获取图片验证码
 */
export function getVerifyPicture(session: any) {
    const timeamsp = Date.now();
    return `${ApiMap.getVerifyPicture}?session=${session}&t=${timeamsp}`;
}

/**
 * @description: 根据用户权限
 */
export function getPermissions() {
    return http.request<{ type: PermissionType, urls: Array<string> }>({
        url: ApiMap.getPermissions,
        method: 'GET'
    });
}

/**
 * @description: 获取用户信息
 */
export async function getUserInfo() {
    const res = await http.request<UserModel>({
        url: ApiMap.getUserInfo,
        method: 'GET'
    });
    UserStoreModule.SET_USER_EXTRA_INFO(res);
    return res;
}

/**
 * @description: 获取用户项目列表
 */
export function getProject() {
    return http.request<Array<ProjectEntityModel>>({
        url: ApiMap.getProject,
        method: 'GET'
    }, { modelType: ProjectEntityModel });
}

export async function initProject(cache: number = 0): Promise<any> {
    const projectList = await getProject();
    if (projectList && projectList.length > 0) {
        const currentProject = _.find(projectList, item => item.id === UserStoreModule.CurrentProjectId) || projectList[0];
        if (localStorage.getItem('SiteType') && localStorage.getItem('SiteType') === 'Crop') {
            UserStoreModule.SET_CURRENT_PROJECT(null);
        } else {
            UserStoreModule.SET_CURRENT_PROJECT(currentProject as any);
        }
    } else {
        UserStoreModule.SET_CURRENT_PROJECT(null);
    }
    return projectList;
}
