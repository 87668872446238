import { get, post, put, del } from './request';

const URL_PATH = `${AUTH_BASE_REQUEST_PATH}/safeconfig`;

class AccountSecurityService {
    async getSafeConfig(type: string): Promise<any> {
        const url = `${URL_PATH}/list/${type}`;
        const res = await get(url);
        return res;
    }

    async updateSafeConfig(body: any): Promise<any> {
        const url = `${URL_PATH}/update`;
        const res = await post(url, body);
        return res;
    }

    /**
     * 检查用户密码是否过期
     * @returns 返回值小于等于0, 即为密码过期,需要弹窗要求修改密码
     */
    async getUserExpiration(): Promise<any> {
        const url = `${AUTH_BASE_REQUEST_PATH}/user/sauth/passwd/expiration`;
        const res = await get(url);
        return res;
    }
}

export default new AccountSecurityService();
