// 是否是公司邮箱
export function isWscnEmail(str: string) {
    const reg = /^[a-z0-9](?:[-_.+]?[a-z0-9]+)*@goldmantis\.com$/i;
    return reg.test(str.trim());
}

// 是否是正确的手机号
export function checkMobile(str: string) {
    const mobileReg = /^1(?:3[0-9]|4[5-9]|5[0-9]|6[12456]|7[0-8]|8[0-9]|9[0-9])[0-9]{8}$/;
    return mobileReg.test(str.trim());
}

// 验证数字和字母
export function checkNumbersOrLetters(str: string) {
    const NLReg = new RegExp(/^[0-9A-Za-z]+$/);
    return NLReg.test(str.trim());
}

// 验证数字+字母+特殊符号
export function checkNumbersOrLettersOrSymbol(str: string, num = 2) {
    const NLReg = new RegExp(/(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%^&*?]{6,16}$/); // 检测两者及以上
    // const NLReg = new RegExp(/^[a-zA-Z0-9]+[!@#$%^&*?]{8,16}$/);
    let count = 0;
    if (/\d+/.test(str)) {
        count++;
    }
    if (/[a-zA-Z]+/.test(str)) {
        count++;
    }
    if (/[!@#$%^&*?_~-]+/.test(str)) {
        count++;
    }
    return NLReg.test(str.trim()) && count >= num;
}

// 验证身份证号
export function validateIdNumber(str: string) {
    const NLReg = new RegExp(/^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/);
    return NLReg.test(str.trim());
}

// 合法uri
export function validateURL(textval: string) {
    const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
    return urlregex.test(textval);
}

// 检测长度8到16位
export function checkLength(str: string) {
    const reg = /^.{8,16}$/;
    return reg.test(str);
}

/**
 * 检查密码强度和格式。
 *
 * @param str 待检查的密码字符串。
 * @param config 配置对象，包含密码检查的规则。
 * @returns 返回布尔值，表示密码是否符合规则。
 */
export function checkPassword(str: string, config: any) {
    if (_.get(config, 'custom')) {
        const tempReg = new RegExp(`^\\S{${_.get(config, 'minimum')},${_.get(config, 'maximum')}}$`);
        if (!tempReg.test(str)) {
            return false;
        }
        if (_.get(config, 'digital')) {
            let reg = /(?=.*\d)/;
            if (!reg.test(str)) {
                return false;
            }
        }
        if (_.get(config, 'alphabet')) {
            let reg = /(?=.*[a-zA-Z])/;
            if (!reg.test(str)) {
                return false;
            }
        }
        if (_.get(config, 'bothCase')) {
            let reg = /^(?=.*[a-z])(?=.*[A-Z])/;
            if (!reg.test(str)) {
                return false;
            }
        }
        if (_.get(config, 'special')) {
            let reg = /(?=.*[^0-9a-zA-Z])/;
            if (!reg.test(str)) {
                return false;
            }
        }
        return true;
    }
}
// 校验IP地址
export function validateIP(ip: string) {
    const reg = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(:\d+)?$/;
    return reg.test(ip);
}

// 比较IP地址
export function compareIP(startIP, endIP) {
    // 将 IP 地址转换为数字表示
    function ipToNumber(ip) {
        var parts = ip.split('.');
        return (parseInt(parts[0]) << 24) +
            (parseInt(parts[1]) << 16) +
            (parseInt(parts[2]) << 8) +
            parseInt(parts[3]);
    }
    return ipToNumber(endIP) >= ipToNumber(startIP);
}
