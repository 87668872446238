


















import { Component, Prop } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import FormComponent from '@common-src/mixins/form-component';
import { FormControlType } from '@common-src/model/form-control';
import { CacheUtil } from '@common-src/model/cache-model';
import { UserStoreModule } from '@common-src/store/modules/user';
import AccountSecurityService from '@common-src/service/account-security';
import { UserPwdService } from '@common-src/service/user-v2';
import { message } from 'ant-design-vue';
import { checkPassword } from '@common-src/utils/validate';
import { UserPwdModel } from '@common-src/entity-model/user-entity';
import { loginout } from '@common-src/service3/user/user';
import { ModuleType } from '@common-src/enum/module-type.enum';

@Component
export default class AccountSettingDialog extends FormDialogComponent<any> {
    dialogVisible: boolean = false;
    config: any = {};
    errMsg: string = '请输入新密码';
    isLogout: boolean = false;

    @Prop({ default: false })
    type: boolean;

    created() {
        AccountSecurityService.getSafeConfig('PASSWORD').then(res => {
            this.config = _.filter(res, r => r.identifier === 'PasswordStrengthPolicy')[0].value;
            if (_.get(this.config, 'custom')) {
                this.errMsg = `${_.get(this.config, 'minimum')}到${_.get(this.config, 'maximum')}个字符，包含${_.get(this.config, 'digital') ? '数字,' : ''}${_.get(this.config, 'special') ? '特殊字符,' : ''}${_.get(this.config, 'alphabet') ? '字母，' : ''}${_.get(this.config, 'bothCase') ? '区分大小写' : ''}`;
            } else {
                this.errMsg = '6到16个字符，包含字母，数字';
            }
            const password = _.filter(res, r => r.identifier === 'InitialPassword')[0].value;
            UserStoreModule.SET_DEFAULT_PASSWORD(_.get(password, 'default'));
        });
    }

    // type为true代表个人中心打开的弹窗
    dialogOpen(type: boolean = false) {
        if (this.type) {
            this.dialogVisible = true;
            this.$nextTick(() => {
                if (this.jtlForm) {
                    // this.jtlForm.initForm(importModel, viewMode);
                    this.jtlForm.initFormByControls([{
                        key: 'originPassword',
                        label: '旧密码',
                        type: FormControlType.TEXT,
                        inputType: 'password',
                        placeholder: `请输入旧密码`,
                        required: true
                    }, {
                        key: 'password',
                        label: '新密码',
                        type: FormControlType.TEXT,
                        validator: this.validatePassword,
                        inputType: 'password',
                        placeholder: `请输入${this.errMsg}`,
                        required: true
                    }, {
                        key: 'newPassword',
                        label: '重复新密码',
                        type: FormControlType.TEXT,
                        validator: this.validateRepeatPassword,
                        inputType: 'password',
                        required: true
                    }] as any);
                }
            });
        } else {
            AccountSecurityService.getUserExpiration().then((res:any) => {
                if (res && Number(res) < 0) {
                    this.dialogVisible = true;
                    this.isLogout = true;
                    this.$nextTick(() => {
                        if (this.jtlForm) {
                        // this.jtlForm.initForm(importModel, viewMode);
                            this.jtlForm.initFormByControls([{
                                key: 'originPassword',
                                label: '旧密码',
                                type: FormControlType.TEXT,
                                inputType: 'password',
                                placeholder: `请输入旧密码`,
                                required: true
                            }, {
                                key: 'password',
                                label: '新密码',
                                type: FormControlType.TEXT,
                                validator: this.validatePassword,
                                inputType: 'password',
                                placeholder: `请输入${this.errMsg}`,
                                required: true
                            }, {
                                key: 'newPassword',
                                label: '重复新密码',
                                type: FormControlType.TEXT,
                                validator: this.validateRepeatPassword,
                                inputType: 'password',
                                required: true
                            }] as any);
                        }
                    });
                }
            });
        }
    }

    validatePassword(rule, value, callback) {
        if (_.isEmpty(value)) {
            callback(new Error('请输入新密码'));
        }

        if (value === _.get(this.jtlForm, 'formModel.originPassword')) {
            callback(new Error('新密码不能与旧密码相同'));
        }

        if (_.get(this.config, 'custom')) {
            if (!checkPassword(value, this.config)) {
                callback(new Error(this.errMsg));
            }
            callback();
        } else {
            const reg = /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)^\S{6,16}$/;
            if (!reg.test(value)) {
                callback(new Error('6个字符及以上，最少包含大写字母、小写字母、数字、符号中的两种'));
            } else {
                callback();
            }
        }
    }

    validateRepeatPassword(rule, value, callback) {
        if (_.isEmpty(value)) {
            callback(new Error('请输入重复新密码'));
        }

        if (value !== _.get(this.jtlForm, 'formModel.password')) {
            callback(new Error('两次密码输入不一致'));
        } else {
            callback();
        }
    }

    dialogOK(): any {
        if (!this.jtlForm) {
            return;
        }

        const body = {
            password: _.get(this.jtlForm, 'formModel.password'),
            id: UserStoreModule.UserId,
            originPassword: _.get(this.jtlForm, 'formModel.originPassword')
        };
        return new Promise((resolve, reject) => {
            this.jtlForm.submitForm().then((ret) => {
                new UserPwdService().resetPassWordWithCheck(body as any).then(res => {
                    message.success('修改成功');
                    this.dialogVisible = false;
                });
            }).catch(err => {
                reject();
                throw err;
            });
        });
    }

    dialogClose(): void {
        this.dialogVisible = false;
        // 退出登录
        if (!this.type && this.isLogout) {
            loginout().then(() => {
                CacheUtil.cleanCache();
                UserStoreModule.SET_USERINFO();
                UserStoreModule.SET_REDIRECT_PATH();
                localStorage.removeItem('title_name');
                localStorage.removeItem('title_logo');
            });

            if (JTL.moduleType === ModuleType.WORKORDER) {
                window.open(location.origin + '/#/sign/login', '_self');
            } else {
                this.$router.push('/sign/login');
            }
        }
    }

    destroyed() {
        this.dialogClose();
    }
}
