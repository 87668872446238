var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "form-edit-component",
      attrs: { title: "修改密码", width: "500px", visible: _vm.dialogVisible },
      on: { cancel: _vm.dialogClose },
    },
    [
      _c(
        "div",
        { staticClass: "import-excel-dialog-component" },
        [_c("jtl-form", { ref: _vm.JTL.CONSTANT.DEFAULT_FORM_NAME })],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("jtl-button", { on: { click: _vm.dialogClose } }, [
            _vm._v("取消"),
          ]),
          _c(
            "jtl-button",
            { attrs: { type: "primary" }, on: { click: _vm.dialogOK } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }